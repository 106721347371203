@media (max-width: 984px) {
  .about-meher-baba-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .about-meher-baba {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .meher-baba-image {
    order: -1;
    width: 80%;
    max-width: 100%;
    height: auto; 
    margin: 20px;
    object-fit: contain;
  }
}
