html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfaf5;
}
p {
  margin: 0;
}

p {
  margin: 0px;
}

body::-webkit-scrollbar {
  width: 0; /* Hide horizontal scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make scrollbar thumb transparent */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Make scrollbar track transparent */
}

img {
  pointer-events: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
